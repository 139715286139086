import { IPublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "75a5767c-8328-4e41-903f-8cdd9b117567",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export interface MeEndpointResponse {
  "@odata.context": string;
  displayName: string;
  surname: string;
  givenName: string;
  id: string;
  userPrincipalName: string;
  businessPhones: Array<string>;
  jobTitle?: string;
  mail?: string;
  mobilePhone?: string;
  officeLocation?: string;
  preferredLanguage?: string;
}

export function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect(loginRequest).catch(console.error);
}

export function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch(console.error);
}

export async function callMeEndpoint(accessToken: String) {
  const options = {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch(console.error);
}
