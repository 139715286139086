import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";

import PomodoricLogo from "./PomodoricLogo";

export interface CoverPageProps {
  onLogin: () => void;
}

export default function CoverPage(props: CoverPageProps) {
  return (
    <>
      <PomodoricLogo sx={{ fontSize: "10em" }} />
      <Box>
        <Typography
          variant="h2"
          gutterBottom
          fontWeight="700"
          fontStyle="italic"
        >
          pomodoric
        </Typography>
        <Typography variant="h5">
          shows how many Pomodoro <br />
          sessions fit in your schedule
        </Typography>
      </Box>
      <Button size="large" startIcon={<LoginIcon />} onClick={props.onLogin}>
        Connect Outlook
      </Button>
    </>
  );
}
