import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";

export default function PomodoricLogo(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect fill={theme.palette.background.default} width="24" height="24" />
      <path
        fill={theme.palette.primary.main}
        d="M19.3,12.7c0-4-3.3-7.3-7.3-7.3c-4,0-7.3,3.3-7.3,7.3C4.7,15.9,7,20,12,20C17.1,20,19.3,15.9,19.3,12.7z
	 M12,18.6c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8C17.8,15.9,15.2,18.6,12,18.6z M11.1,13.6L9.4,12
	l-1,1l2.7,2.6l4.8-4.8l-1-1C14.8,9.8,11.1,13.6,11.1,13.6z M3.8,9.6C3.4,9,3.3,8.3,3.3,7.6c0-2,1.6-3.6,3.6-3.6c0.7,0,1.4,0.2,2,0.6
	C6.6,5.5,4.7,7.3,3.8,9.6z M17.1,4c-0.7,0-1.4,0.2-2,0.6c2.3,0.9,4.2,2.7,5,5c0.4-0.6,0.6-1.3,0.6-2C20.7,5.6,19.1,4,17.1,4z"
      />
    </SvgIcon>
  );
}
