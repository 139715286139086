import { useState, useEffect } from "react";
import { PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import {
  loginRequest,
  callMeEndpoint,
  MeEndpointResponse,
} from "../utils/graph";

export default function Schedule() {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState<MeEndpointResponse | null>(null);

  useEffect(() => {
    const request: PopupRequest = {
      account: accounts[0],
      ...loginRequest,
    };

    // fetch the data and possibly refresh the token
    instance
      .acquireTokenSilent(request)
      .then((response) =>
        callMeEndpoint(response.accessToken).then(setGraphData)
      )
      .catch(() =>
        instance
          .acquireTokenPopup(request)
          .then((response) =>
            callMeEndpoint(response.accessToken).then(setGraphData)
          )
      );
  }, [instance, accounts]);

  if (!graphData) return <CircularProgress />;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell align="right">{graphData.givenName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Name</TableCell>
            <TableCell align="right">{graphData.surname}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">{graphData.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>E-Mail</TableCell>
            <TableCell align="right">{graphData.userPrincipalName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
