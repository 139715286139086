import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

export interface BurgerMenuProps {
  userName: String;
  onSettings: () => void;
  onDisconnect: () => void;
}

export default function BurgerMenu(props: BurgerMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const clickMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <Box sx={{ position: "absolute", margin: "1em" }}>
      <IconButton
        id="burger-button"
        size="large"
        color="inherit"
        aria-label="menu"
        aria-haspopup="true"
        aria-controls={isOpen ? "burger-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        onClick={clickMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="burger-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        MenuListProps={{ "aria-labelledby": "burger-button" }}
      >
        <MenuItem disabled>
          Connected to <br /> {props.userName}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            props.onSettings?.();
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            props.onDisconnect();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Disconnect</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}
