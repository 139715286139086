import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Container from "@mui/material/Container";

import BurgerMenu from "./BurgerMenu";
import CoverPage from "./CoverPage";
import Schedule from "./Schedule";

import { handleLogin, handleLogout } from "../utils/graph";

import "./App.css";

export default function App() {
  const { instance, accounts } = useMsal();
  const accountName = accounts[0]?.username;

  return (
    <>
      <AuthenticatedTemplate>
        <BurgerMenu
          onSettings={() => console.error("settings not implemented")}
          onDisconnect={() => handleLogout(instance)}
          userName={accountName}
        />
      </AuthenticatedTemplate>

      <Container className="AppContainer" maxWidth="sm">
        <AuthenticatedTemplate>
          <Schedule />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <CoverPage onLogin={() => handleLogin(instance)} />
        </UnauthenticatedTemplate>
      </Container>
    </>
  );
}
